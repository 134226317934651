<template>
  <div :style="[{ height: height + 'px' }]" class="home_container">
    <el-backtop target=".home_container" :bottom="100"></el-backtop>
    <el-row>
      <el-card >
        <el-col :span="16" align="right">
          <el-radio-group v-model="tabPosition" style="margin-bottom: 10px">
            <el-radio-button label="today">今天</el-radio-button>
            <el-radio-button label="lastday">昨天</el-radio-button>
            <el-radio-button label="week">过去7天</el-radio-button>
            <el-radio-button label="month">过去30天</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="7">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dateRange"
            @change="dateChanged"
            type="datetimerange"
            range-separator="~"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-card>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="font-weight: 600">数据概览({{ projectName }})</span>
        </div>
        <el-row :gutter="24">
          <el-col :span="3" class="data-col" :key="index" v-for="(item, index) in topDataArr">
            <data-item :data="item" />
          </el-col>
        </el-row>
      </el-card>
    </el-row>
    <el-row :gutter="24" style="margin-top: 5px">
      <el-col style="width: 20%">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">工地项目列表</span>
          </div>
          <el-table height="250" :data="dataObj.projectList" style="width: 100%">
            <el-table-column show-overflow-tooltip  align="center" prop="name" label="名称"></el-table-column>
            <el-table-column align="center" prop="count" label="工单数"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col style="width: 20%">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">工地未结算列表</span>
          </div>
          <el-table height="250" :data="dataObj.notWorkSettlementList" style="width: 100%">
            <el-table-column show-overflow-tooltip  align="center" prop="name" label="名称"> </el-table-column>
            <el-table-column align="center" prop="count" label="工单数"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col style="width: 20%">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">管理员列表</span>
          </div>
          <el-table v-if="dataObj.workList" height="250" :data="dataObj.workList" style="width: 100%">
            <el-table-column show-overflow-tooltip  align="center" prop="name" label="姓名"> </el-table-column>
            <el-table-column align="center" prop="count" label="工单数"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col style="width: 20%">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-weight: 550; font-size: 16px">车辆列表</span>
          </div>
          <el-table v-if="dataObj.carList" height="250" :data="dataObj.carList" style="width: 100%">
            <el-table-column show-overflow-tooltip  align="center" prop="name" label="车牌号"> </el-table-column>
            <el-table-column align="center" prop="count" label="工单数"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col style="width: 20%">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">车队列表</span>
          </div>
          <el-table height="250" :data="dataObj.fleetList" style="width: 100%">
            <el-table-column show-overflow-tooltip  align="center" prop="name" label="车队"> </el-table-column>
            <el-table-column align="center" prop="count" label="工单数"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col style="width: 32.5%; margin-right: 5px">
        <el-card>
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">异常单占比</span>
          </div>
          <div v-if="dataObj !== null" style="width: 100%; text-align: center; font-weight: 400; font-style: normal">
            工单数：<span style="color: #247eff">{{ dataObj.pcViewBossException.exceptionCount + dataObj.pcViewBossException.normalCount || 0 }}</span
            >条
            <br>
            <br>
          </div>
          <pie-chart
            v-if="pcViewBossException !== null"
            :dataList="pcViewBossException.dataList"
            :total="pcViewBossException.total"
            :title="pcViewBossException.title"
          />
        </el-card>
      </el-col>
      <el-col style="width: 32.5%; margin-left: 6px; margin-right: 6px">
        <el-card>
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">土类型工单占比</span>
          </div>
          <div v-if="dataObj !== null" style="width: 100%; text-align: center; font-weight: 400; font-style: normal">
            工单数:<span style="color: #247eff">{{ dataObj.pcViewBossTlxPercentage.orderCount || 0 }}</span
            >条 <br>土方数:<span style="color: #247eff">{{ dataObj.pcViewBossTlxPercentage.earthWorkCount || 0 }}</span>
            方
          </div>
          <pie-chart
            v-if="pcViewBossTlxPercentage !== null"
            :dataList="pcViewBossTlxPercentage.dataList"
            :total="pcViewBossTlxPercentage.total"
            :title="pcViewBossTlxPercentage.title"
          />
        </el-card>
      </el-col>
      <el-col style="width: 32.5%; margin-left: 5px">
        <el-card>
          <div slot="header" class="clearfix">
            <span style="font-weight: 550">指定渣土场、自倒工单占比</span>
          </div>
          <div v-if="dataObj !== null" style="width: 100%; text-align: center; font-weight: 400; font-style: normal">
            工单数：<span style="color: #247eff">{{ dataObj.pcViewBossZtcPercentage.notZdCount + dataObj.pcViewBossZtcPercentage.zdCount || 0 }}</span
            >条<br>土方数:<span style="color: #247eff">{{ dataObj.pcViewBossZtcPercentage.earthWorkCount || 0 }}</span
            >方
          </div>
          <pie-chart
            v-if="pcViewBossZtcPercentage !== null"
            :dataList="pcViewBossZtcPercentage.dataList"
            :total="pcViewBossZtcPercentage.total"
            :title="pcViewBossZtcPercentage.title"
          />
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-card>
        <div slot="header" class="clearfix">
          <span style="font-weight: 550">工单趋势</span>
          <div style="float: right">
            <el-select @change="getOrderTrend" style="width: 100px" size="small" v-model="year">
              <el-option v-for="y in yearList" :label="y" :value="y" :key="y" />
            </el-select>
          </div>
        </div>
        <div style="width: 100%; text-align: center; font-weight: 400; font-style: normal">
          累计工单：<span style="color: #247eff">{{ orderTrendCount }}</span
          >条 累计土方：<span style="color: #247eff">{{ orderTrendEarth }}</span
          >方
        </div>
        <biax-chart :data="orderTrendList" />
      </el-card>
    </el-row>
  </div>
</template>
<script>
import DataItem from '@/views/dashboard/components/dataItem'
import PieChart from '@/views/dashboard/components/pieChart'
import BiaxChart from '@/views/dashboard/components/biaxChart'
import { previewData, orderTrend } from '@/api/home'
import dayjs from 'dayjs'
export default {
  components: { DataItem, PieChart, BiaxChart },
  data() {
    return {
      tabPosition: 'today',
      projectName: '',
      projectId: '',
      dateRange: [],
      queryForm1: {
        projectId: '',
        startTime: '',
        endTime: ''
      },
      year: new Date().getFullYear(),
      yearList: [],
      dataObj: null,
      topDataArr: [], //顶部个块的数据集合
      // 工地数量
      projectCount: {
        title: '我的工地',
        tip: '老板在好运来系统中创建的正常工地',
        total: 0,
        openCount: 0,
        notOpenCount: 0,
        isProject: true
      },
      // 工单数
      orderCount: {
        title: '工单数',
        tip: '单位时间内老板所有工地管理员放行车辆产生的工单（车）数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },
      // 自倒车数
      zdCount: {
        title: '自倒车数',
        tip: '单位时间内老板所有工地车辆出场后自倒的工单（车）数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },
      //渣土场车数
      ztcCount: {
        title: '渣土场车数',
        tip: '单位时间内老板所有工地车辆出场后去往指定渣土场的工单（车）数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },
      //车队数
      fleetCount: {
        title: '车队数',
        tip: '单位时间内老板所有工地拉土的车队数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },

      //活跃车辆数
      carCount: {
        title: '活跃车辆数',
        tip: '单位时间内老板所有工地车辆进出场的总工单（车）数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },

      //活跃司机数
      driverCount: {
        title: '活跃司机数',
        tip: '单位时间内老板所有工地司机进出场的总数',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },
      // 土方量
      tlfCount: {
        title: '土方量',
        tip: '单位时间内老板所有工地车辆运土的总方量',
        total: 0,
        dayCount: 0,
        nightCount: 0,
        isProject: false
      },
      // 异常工单
      pcViewBossException: null,
      // 土类型工单占比
      pcViewBossTlxPercentage: null,
      // 指定渣土场、自倒工单占比
      pcViewBossZtcPercentage: null,
      // 订单趋势数据集合
      orderTrendList: [],
      orderTrendCount: 0,
      orderTrendEarth: 0,
      pieData: {
        title: ''
      },
      height: document.documentElement.clientHeight - 80 //实时屏幕高度
    }
  },
  created() {
    let projectId = this.$route.query.id
    this.projectId = projectId
    this.queryForm1.projectId = projectId
    this.projectName = this.$route.query.name
    let date = new Date()
    let currentYear = date.getFullYear()
    this.year = currentYear
    this.yearList = []
    for (let i = 0; i < 5; i++) {
      this.yearList.push(currentYear - i)
    }
    this.dateRange = []
    this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 00:00:00')
    this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 23:59:59')
    this.getOrderTrend()
  },
  mounted() {
    let that = this
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.height = window.fullHeight - 80 // 高
      })()
    }
  },
  watch: {
    tabPosition: {
      handler(val, oldVal) {
         if (val === 'today') {
          this.dateRange = []
          this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 00:00:00')
          this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 23:59:59')
        }
        if (val === 'lastday') {
          this.dateRange = []
          this.dateRange.push(dayjs().subtract(1, 'day').format('YYYY-MM-DD') + ' 00:00:00')
          this.dateRange.push(dayjs().subtract(1, 'day').format('YYYY-MM-DD') + ' 23:59:59')
        }
        if (val === 'week') {
          this.dateRange = []
          this.dateRange.push(dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00')
          this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 23:59:59')
        }
        if (val === 'month') {
          this.dateRange = []
          this.dateRange.push(dayjs().subtract(30, 'day').format('YYYY-MM-DD') + ' 00:00:00')
          this.dateRange.push(dayjs().format('YYYY-MM-DD') + ' 23:59:59')
        }
        this.getDataPreview()
      }
    },
    dateRange: {
      handler(val, oldVal) {
        if (val.length == 2) {
          this.queryForm1.startTime = val[0]
          this.queryForm1.endTime = val[1]
          this.getDataPreview()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    dateChanged(v) {
      this.dateRange = v
      this.tabPosition = ''
    },
    // 获取数据预览部分数据
    getDataPreview() {
      previewData(this.queryForm1).then(res => {
        this.dataObj = res.data
        // 我的工地 --
        let projectCount = res.data.projectCount
        this.projectCount.notOpenCount = projectCount.notOpenCount
        this.projectCount.openCount = projectCount.openCount
        this.projectCount.total = projectCount.projectCount
        //--
        // 工单数
        let orderCount = res.data.orderCount
        this.orderCount.dayCount = orderCount.dayCount
        this.orderCount.nightCount = orderCount.nightCount
        this.orderCount.total = orderCount.count
        //--
        // 自倒车数
        let zdCount = res.data.zdCount
        this.zdCount.dayCount = zdCount.dayCount
        this.zdCount.nightCount = zdCount.nightCount
        this.zdCount.total = zdCount.count
        //--

        // 渣土场车数
        let ztcCount = res.data.ztcCount
        this.ztcCount.dayCount = ztcCount.dayCount
        this.ztcCount.nightCount = ztcCount.nightCount
        this.ztcCount.total = ztcCount.count
        //--

        // 车队数
        let fleetCount = res.data.fleetCount
        this.fleetCount.dayCount = fleetCount.dayCount
        this.fleetCount.nightCount = fleetCount.nightCount
        this.fleetCount.total = fleetCount.count
        //--

        // 活跃车辆数
        let carCount = res.data.carCount
        this.carCount.dayCount = carCount.dayCount
        this.carCount.nightCount = carCount.nightCount
        this.carCount.total = carCount.count
        //--

        // 活跃司机数
        let driverCount = res.data.driverCount
        this.driverCount.dayCount = driverCount.dayCount
        this.driverCount.nightCount = driverCount.nightCount
        this.driverCount.total = driverCount.count
        //--

        // 土方量
        let tlfCount = res.data.tlfCount
        this.tlfCount.dayCount = tlfCount.dayCount
        this.tlfCount.nightCount = tlfCount.nightCount
        this.tlfCount.total = tlfCount.count
        //--
        this.topDataArr = []
        this.topDataArr.push(this.projectCount)
        this.topDataArr.push(this.orderCount)
        this.topDataArr.push(this.zdCount)
        this.topDataArr.push(this.ztcCount)
        this.topDataArr.push(this.fleetCount)
        this.topDataArr.push(this.carCount)
        this.topDataArr.push(this.driverCount)
        this.topDataArr.push(this.tlfCount)

        // 图表数据
        //异常工单
        let pcViewBossException = res.data.pcViewBossException
        let pcViewBossExceptionObj = {
          dataList: [],
          title: '',
          total: 0
        }
        pcViewBossExceptionObj.dataList.push({ item: '异常单', count: pcViewBossException.exceptionCount })
        pcViewBossExceptionObj.dataList.push({ item: '正常单', count: pcViewBossException.normalCount })
        pcViewBossExceptionObj.total = pcViewBossException.normalCount + pcViewBossException.exceptionCount
        pcViewBossExceptionObj.title = '工单数：' + (pcViewBossException.normalCount + pcViewBossException.exceptionCount) + '条'
        this.pcViewBossException = pcViewBossExceptionObj

        // 土类型
        let pcViewBossTlxPercentageList = res.data.pcViewBossTlxPercentage.innerPCViewBossTlxPercentageList
        let pcViewBossTlxPercentageObj = {
          dataList: [],
          title: '',
          total: 0
        }
        pcViewBossTlxPercentageList.forEach(item => {
          let obj = { item: item.tlxName, count: item.count }
          pcViewBossTlxPercentageObj.dataList.push(obj)
        })
        pcViewBossTlxPercentageObj.total = res.data.pcViewBossTlxPercentage.orderCount
        this.pcViewBossTlxPercentage = pcViewBossTlxPercentageObj

        // ---

        // 指定渣土场、自倒工单占比
        let pcViewBossZtcPercentage = res.data.pcViewBossZtcPercentage
        let pcViewBossZtcPercentageObj = {
          dataList: [],
          title: '',
          total: 0
        }
        pcViewBossZtcPercentageObj.dataList.push({ item: '自倒', count: pcViewBossZtcPercentage.zdCount })
        pcViewBossZtcPercentageObj.dataList.push({ item: '指定渣土场', count: pcViewBossZtcPercentage.notZdCount })
        pcViewBossZtcPercentageObj.total = pcViewBossZtcPercentage.zdCount + pcViewBossZtcPercentage.notZdCount
        pcViewBossZtcPercentageObj.title = '工单数：' + (pcViewBossZtcPercentage.zdCount + pcViewBossZtcPercentage.notZdCount) + '条'
        this.pcViewBossZtcPercentage = pcViewBossZtcPercentageObj
      })
    },

    // 获取工单趋势
    getOrderTrend() {
      let params = {
        year: this.year,
        projectId: this.projectId
      }
      orderTrend(params).then(res => {
        this.orderTrendCount = res.data.orderCount || 0
        this.orderTrendEarth = res.data.earthWorkCount || 0
        let orderCountMap = res.data.orderCountMap
        let earthWorkCountMap = res.data.earthWorkCountMap
        const m1 = Object.keys(orderCountMap)
        const m2 = Object.keys(earthWorkCountMap)
        let intersectMonth = new Set([...m1, ...m2])
        let arr = []
        intersectMonth.forEach(item => {
          let obj = {
            月份: item,
            工单: orderCountMap[item] || 0,
            土方: earthWorkCountMap[item] || 0
          }
          arr.push(obj)
        })
        this.orderTrendList = arr
      })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard {
  &-container {
    background: url('/static/dashboard.svg') no-repeat 50%;
    height: 680px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
.home_container {
  margin: 5px 10px 60px 10px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 100vh;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.home_container::-webkit-scrollbar {
  display: none;
}
.data-col {
  margin: 0px 0px 0px 0px;
}
::v-deep .el-card__body {
  padding: 5px !important;
}
::v-deep .el-card__header {
  padding: 12px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}
</style>
